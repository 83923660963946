body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -----------------common-css-style---------------- */
/* .input-fields-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.succe-img img,
.input-fields-image img,
.brands-logo img,
.navbar-brand img,
.website-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
a {
  text-decoration: none;
}
p {
  font-size: 14px;
}

.career_delete,
.grid-items,
.login-form,
.btn-group button,
.website-grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* -------------------------navbar-section-start------------------------- */
.navbar-brand {
  width: 20%;
  height: 8vh;
}
.enter-website {
  padding: 10px;
  background-color: aliceblue;
  border-radius: 6px;
}
.website-name h5 {
  text-decoration: none;
}
.brands-logo,
.website-logo {
  width: 100%;
  height: 16vh;
}
.enter-website:hover h5 {
  text-decoration: underline;
}

.website-grid {
  height: 85vh;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 20px;
}
.second-grid {
  height: auto !important;
  width: 23%;
}

.dropdown-toggle::after {
  display: none;
}
.btn-group button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.btn-group button:focus {
  box-shadow: none;
}
.btn-group ul {
  padding: 10px !important;
}
.btn-group ul li a {
  font-size: 13px;
  border-radius: 3px !important;
}
.btn-group ul li button {
  width: 100% !important;
  border-radius: 3px !important;
  font-size: 13px;
  height: 30px;
  color: #fff;
}

.btn-group ul li button:hover {
  color: #fff;
  background-color: rgb(135, 0, 0);
}

/* ----------------------login-form-section------------ */
.form-bg-section {
  background-image: url("https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg?t=st=1715326139~exp=1715329739~hmac=fa52f6c6db8d09246a41075eb81bdf894f86f988652d49ff16e7523429200ee8&w=1060");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.forgot-password-section {
  background-image: url("https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg?t=st=1715326139~exp=1715329739~hmac=fa52f6c6db8d09246a41075eb81bdf894f86f988652d49ff16e7523429200ee8&w=1060");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-form {
  height: 100vh;
}
.login-form form {
  width: 30%;
  padding: 1rem;
  background-color: #ffffff3d;
  backdrop-filter: blur(10px);
  border-radius: 10px;
}
.input-fields {
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
  height: 10vh;
}
.input-fields p,
.input-fields label {
  color: #fff;
}
.input-fields label {
  font-size: 14px;
}
.input-fields input {
  width: 100%;
  padding: 6px;
  margin-top: 10px;
  border: none;
  border-radius: 2px;
  color: gray;
  font-size: 14px;
}
.input-fields input:focus {
  outline: none;
  border-bottom: 3px solid rgb(6, 108, 6);
}

.input-fields h5 {
  font-size: 2rem;
  color: #fff;
}

.forgot-password a {
  font-size: 12px;
  color: #fff;
}
.signup-redirect a:hover,
.forgot-password a:hover {
  text-decoration: underline;
}
.submit-button {
  height: auto !important;
}

.submit-button button {
  width: 100%;
}
.name-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.name-section .input-fields {
  flex-basis: 48%;
}

.navbar-username {
  font-size: 20px;
  text-transform: uppercase;
}

/* -------------------brands-details------------------------ */
.side-navbar {
  height: 85vh;
}

.side-navbar ul {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
}
.side-navbar ul li {
  list-style: none;
  text-indent: 15px;
  line-height: 30px;
}

.side-navbar ul li a {
  margin-bottom: 10px;
  display: block;
  text-decoration: none;
  color: #999999;
  font-size: 13px;
}

.side-navbar ul li a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #242424;
  border-radius: 5px;
}

.side-navbar ul li a:active,
.side-navbar ul li a:focus {
  text-decoration: none;
}

.verify-btn,
table tbody tr a,
.pannel-btn a {
  padding: 4px 14px !important;
  font-size: 12px !important;
}

.media-grid {
  grid-gap: 18px;
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  margin-top: 1rem;
}
.grid-items {
  height: 20vh;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.grid-items h4 {
  font-size: 1rem;
  text-transform: uppercase;
}

.career_delete {
  height: 100%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-top: 6px;
  color: #fff;
}

/* .heading-section{

} */
table thead tr {
  font-size: 14px;
}
table tbody tr {
  font-size: 13px;
}

.scroll-section {
  height: 87vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ----------------team-card------------- */
.team-card {
  border: 1px solid rgb(226, 226, 226);
  text-align: center;
  border-radius: 10px;
  position: relative;
}
.team-card img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.team-card h5 {
  font-size: 15px;
  margin-bottom: 3px;
  text-align: left;
}
.team-card h6 {
  font-size: 12px;
}
.gallery-material button,
.delete-material button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 112, 112);
  color: #fff;
  border: none;
}




/* --------------------add-team-section------------------- */
.form-data .input-fields{
  height: auto !important;
  margin-bottom: 14px;
}
.form-data .input-fields label {
  color: #000;
  font-size: 14px;
}
.form-data .input-fields select,
.form-data .input-fields button,
.form-data .input-fields input {
  width: 100%;
  padding: 10px !important;
  height: 40px ;
  font-size: 13px;
  color: #171717;
  background-color: #fff;
  border: 1px solid #ededed;
}

.form-data .input-fields select:focus,
.form-data .input-fields button:focus,
.form-data .input-fields input:focus {
  border-bottom: 2px solid #292929;
}

.form-data .input-fields button{
  background-color: #000;
  color: #fff;
}


.gallery-material{
  position: absolute;
  top: 10px;
  right: 10px;
}

.gallery-card img{
  border-radius: 10px !important;
}








.succe-img{
  width: 80px;
  height: 80px;
  margin: 0 auto;
}